import { Fragment } from "react";
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
} from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import {
	awayPlayer,
	awayPlayerClub,
	Game,
	homePlayer,
	homePlayerClub,
} from "./testData";

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(" ");
}

interface Props {
	game: Game;
}

export const GamesItem = ({ game }: Props) => {
	const statuses: any = {
		// complete
		1: "text-green-700 bg-green-50 ring-green-600/20",
		// in progress
		2: "text-gray-600 bg-gray-50 ring-gray-500/10",
		// archived
		3: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
	};

	const getStatus = (status: number): string => {
		switch (status) {
			case 1:
				return "Complete";
			case 2:
				return "In progress";
			case 3:
				return "Archived";
			default:
				return "";
		}
	};

	const handleNavigate = (url: any) => {
		window.location = url;
	};

	return (
		<li key={game.id}>
			<div className="flex items-center justify-between gap-x-6 py-5">
				<div className="min-w-0">
					<div className="flex items-start gap-x-3">
						<p className="text-sm font-semibold leading-6 text-gray-900">
							{homePlayerClub
								? homePlayerClub.clubName
								: homePlayer?.userName}{" "}
							VS{" "}
							{awayPlayerClub
								? awayPlayerClub.clubName
								: awayPlayer?.userName}
						</p>
						<p
							className={classNames(
								statuses[game.status],
								"rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
							)}
						>
							{getStatus(game.status)}
						</p>
					</div>
					<div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
						<p className="whitespace-nowrap">
							Started{" "}
							<time dateTime={game.createdAt.toDateString()}>
								{game.createdAt.toDateString()}
							</time>
						</p>
					</div>
				</div>
				<div className="flex flex-none items-center gap-x-4">
					<Menu as="div" className="relative flex-none">
						<MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
							<span className="sr-only">Open options</span>
							<EllipsisVerticalIcon
								className="h-5 w-5"
								aria-hidden="true"
							/>
						</MenuButton>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<MenuItems className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
								<MenuItem>
									{({ focus }) => (
										<a
											href="/"
											className={classNames(
												focus ? "bg-gray-50" : "",
												"block px-3 py-1 text-sm leading-6 text-gray-900"
											)}
										>
											Delete
										</a>
									)}
								</MenuItem>
							</MenuItems>
						</Transition>
					</Menu>
				</div>
			</div>
			{game.status === 2 && (
				<button
					type="button"
					className="w-full rounded-md bg-cyan-950 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-800"
					onClick={() => handleNavigate(`/game/${game.id}`)}
				>
					View Game
				</button>
			)}
		</li>
	);
};
