import { NavShell } from "./NavShell";

function App() {
  return (
    <div>
      <NavShell />
    </div>
  );
}

export default App;
