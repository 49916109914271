import { useState } from "react";
import { GamesItem } from "./GamesItem";
import { games } from "./testData";

function classNames(...classes: any) {
	return classes.filter(Boolean).join(" ");
}

export const GamesList = () => {
	const [filterStatus, setFilterStatus] = useState<number | null>(null);

	return (
		<>
			<div className="flex w-full justify-center">
				<span className="isolate inline-flex rounded-md shadow-sm">
					<button
						type="button"
						className={classNames(
							filterStatus === null
								? "bg-orange-600"
								: "bg-white hover:bg-orange-50",
							"relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-orange-50 focus:z-10"
						)}
						onClick={() => setFilterStatus(null)}
					>
						All
					</button>
					<button
						type="button"
						className={classNames(
							filterStatus === 2
								? "bg-orange-600"
								: "bg-white hover:bg-orange-50",
							"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
						)}
						onClick={() => setFilterStatus(2)}
					>
						In Progress
					</button>
					<button
						type="button"
						className={classNames(
							filterStatus === 1
								? "bg-orange-600"
								: "bg-white hover:bg-orange-50",
							"relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
						)}
						onClick={() => setFilterStatus(1)}
					>
						Completed
					</button>
					<button
						type="button"
						className={classNames(
							filterStatus === 3
								? "bg-orange-600"
								: "bg-white hover:bg-orange-50",
							"relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
						)}
						onClick={() => setFilterStatus(3)}
					>
						Archived
					</button>
				</span>
			</div>
			<ul className="divide-y divide-gray-200">
				{games.map((game) => (
					<li key={game.id} className="py-4">
						<GamesItem game={game} />
					</li>
				))}
			</ul>
		</>
	);
};
