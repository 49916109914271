export const awayPlayerClub = {
	clubName: "Dem boys",
};

export const homePlayerClub = {
	clubName: "Doze Guys",
};

export const homePlayer = {
	userName: "smesj",
};

export const awayPlayer = {
	userName: "Other Guy",
};

export const games = [
	{
		id: 1,
		status: 2,
		createdAt: new Date(),
	},	{
		id: 2,
		status: 2,
		createdAt: new Date(),
	},
];

export interface Game {
    id: number;
    status: number;
    createdAt: Date;
}
