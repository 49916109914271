import { Fragment } from "react";
import { GamesList } from "./GamesList";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GameView } from "./GameView";
import { LoginForm } from "./LoginForm";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const NavShell = () => {
  const loggedIn = true;
  const user = {
    userName: "Smesj",
    imageUrl: null,
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <GamesList />,
    },
    // {
    //   path: "/new-game",
    //   element: <NewGameForm />,
    // },
    // {
    //   path: "/teams-list",
    //   element: <TeamsList />,
    // },
    {
      path: "/game/:id",
      element: <GameView />,
    },
    // {
    //   path: "/profile/:userId",
    //   element: <ProfileView user={user}/>,
    // },
  ]);

  const navigation = [
    { name: "Dashboard", href: "/", current: true },
    // { name: "New Game", href: "/new-game", current: false },
    // { name: "Teams", href: "/teams-list", current: false },
  ];

  const userNavigation = [
    { name: "Your Profile", href: `/` },
    { name: "Sign out", onClick: () => handleLogout() },
  ];

  const handleLogout = () => {
    // TODO with auth
  };

  const handleNavigate = (url: any) => {
    window.location = url;
  };

  return (
    <>
      {loggedIn ? (
        <div className="min-h-full">
          <div className="bg-gray-800 pb-32">
            <Disclosure as="nav" className="bg-gray-800">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="border-b border-orange-800">
                      <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <img
                              className="h-12 w-12"
                              src="/logo.svg"
                              alt="Your Company"
                            />
                          </div>
                          <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                              {navigation.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-900 text-white"
                                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                    "rounded-md px-3 py-2 text-sm font-medium"
                                  )}
                                  aria-current={
                                    item.current ? "page" : undefined
                                  }
                                >
                                  {item.name}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div className="ml-4 flex items-center md:ml-6">
                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                              <div>
                                <MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                  <span className="absolute -inset-1.5" />
                                  <span className="sr-only">
                                    Open user menu
                                  </span>
                                  <span className="pr-4 text-white">
                                    {user?.userName}
                                  </span>
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src={
                                      user?.imageUrl ?? "/default-profile.svg"
                                    }
                                    alt=""
                                  />
                                </MenuButton>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {userNavigation.map((item) => (
                                    <MenuItem key={item.name}>
                                      {({ focus }) => (
                                        <a
                                          href={item.href}
                                          onClick={item.onClick}
                                          className={classNames(
                                            focus ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </MenuItem>
                                  ))}
                                </MenuItems>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                          {/* Mobile menu button */}
                          <DisclosureButton className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                              <XMarkIcon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            ) : (
                              <Bars3Icon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            )}
                          </DisclosureButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DisclosurePanel className="border-b border-gray-700 md:hidden">
                    <div className="space-y-1 px-2 py-3 sm:px-3">
                      {navigation.map((item) => (
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "block rounded-md px-3 py-2 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </DisclosureButton>
                      ))}
                    </div>
                    <div className="border-t border-gray-700 pb-3 pt-4">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user?.imageUrl ?? "/default-profile.svg"}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium leading-none text-white">
                            {user?.userName}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 space-y-1 px-2">
                        {userNavigation.map((item) => (
                          <DisclosureButton
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                          >
                            {item.name}
                          </DisclosureButton>
                        ))}
                      </div>
                    </div>
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex justify-between">
                <h1 className="text-3xl font-bold tracking-tight text-white">
                  Table Footy
                </h1>
                <div className="text-white content-center">
                  <button
                    onClick={() => handleNavigate("new-game")}
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-orange-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                  >
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    New Game
                  </button>
                </div>
              </div>
            </header>
          </div>

          <main className="-mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
                <RouterProvider router={router} />
              </div>
            </div>
          </main>
        </div>
      ) : (
        <LoginForm />
      )}
    </>
  );
};
